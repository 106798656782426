<template>
  <b-modal id="existingPayeesModal" hide-footer no-close-on-backdrop>
    <validation-observer ref="addPayeeRules" v-slot="{ invalid }">
      <b-form class="mt-2" @submit.prevent="handleSubmit()">
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <h2>Agregar beneficiaros</h2>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Nombre legal" label-for="payee-legal-name">
              <validation-provider
                #default="{ errors, valid }"
                name="legalName"
                rules="required"
              >
                <b-form-input
                  v-model="payee.legalName"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  placeholder="Nombre legal"
                  name="legalName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="E-mail" label-for="payee-e-mail">
              <validation-provider
                #default="{ errors, valid }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="payee.email"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  name="Email"
                  placeholder="E-mail"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="RFC" label-for="payee-tax-id">
              <validation-provider
                #default="{ errors, valid }"
                name="taxId"
                rules="required"
              >
                <b-form-input
                  v-model="payee.taxId"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  name="taxId"
                  placeholder="RFC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Codigo postal" label-for="payee-zip-code">
              <validation-provider
                #default="{ errors, valid }"
                name="ZipCode"
                rules="required|zip-code"
              >
                <b-form-input
                  v-model="payee.zipCode"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  name="zipCode"
                  placeholder="Codigo postal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Tipo de persona" label-for="payee-type">
              <validation-provider
                v-slot="{ errors, valid }"
                name="PayeeType"
                rules="required"
              >
                <v-select
                  v-model="payee.personType"
                  :options="personTypeOptions"
                  :reduce="(val) => val.value"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  label="name"
                  class="h-10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Regimen fiscal"
              label-for="payee-fiscal-regime"
            >
              <validation-provider
                #default="{ errors, valid }"
                name="FiscalRegime"
                rules="required"
              >
                <v-select
                  v-model="payee.fiscalRegime"
                  :options="taxRegimes"
                  label="name"
                  class="h-10"
                  name="FiscalRegime"
                  :state="errors.length > 0 ? false : valid ? true : null"
                  :reduce="(val) => val.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Cdfi Uso" label-for="payee-cfdi-use">
              <validation-provider
                #default="{ errors }"
                name="CfdiUse"
                rules="required"
              >
                <v-select
                  v-model="payee.cfdiUse"
                  :options="cfdiUses"
                  label="name"
                  class="h-10"
                  :reduce="(val) => val.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Registrar beneficiario
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-danger"
              type="reset"
              class="mt-2"
              @click="$bvModal.hide('existingPayeesModal')"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

import vSelect from "vue-select";

import { required, email, zipCode } from "@validations";

import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    establishmentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      zipCode,
      payee: {
        legalName: "",
        email: "",
        taxId: "",
        fiscalRegime: "",
        cfdiUse: "",
        zipCode: "",
        personType: "",
      },
      personTypeOptions: [
        {
          name: "Persona física",
          value: "natural",
        },
        {
          name: "Persona moral",
          value: "fiscal",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchCfdiUses();
    this.fetchTaxRegimes();
  },
  computed: {
    ...mapGetters("catalogsSat", ["taxRegimes", "cfdiUses"]),
  },
  methods: {
    ...mapActions("payees", ["registerPayee", "fetchPayeeTaxes"]),
    ...mapActions("catalogsSat", ["fetchCfdiUses", "fetchTaxRegimes"]),
    handleSubmit() {
      this.$refs.addPayeeRules.validate().then((success) => {
        if (success) {
          this.registerPayee({
            payee_tax_information: {
              tax_id: this.payee.taxId,
              legal_name: this.payee.legalName,
              email: this.payee.email,
              fiscal_regime: this.payee.fiscalRegime,
              cfdi_use: this.payee.cfdiUse,
              tax_zip_code: this.payee.zipCode,
              person_type: this.payee.personType,
              establishment_id: this.establishmentId,
            },
          })
            .then((res) => {
              this.$bvModal.hide("existingPayeesModal");
              this.$swal({
                title: "Beneficiario agregado!",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.fetchPayeeTaxes({
                by_establishment: this.establishmentId,
              });
            })
            .catch((err) => {
              const errorMessage = err.response.data;
              const formattedErrors = Object.keys(errorMessage).map((key) => {
                return errorMessage[key][0];
              });
              this.$swal({
                title: formattedErrors
                  ? formattedErrors[0]
                  : "Error al agregar beneficiario",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
